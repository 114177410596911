import React from 'react';

const IcoArrowRight: React.FC = () => {
    return (
        <svg
            width="57"
            height="57"
            viewBox="0 0 57 57"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx="28.0816" cy="28.0816" r="27.5816" stroke="black" />
            <path
                d="M26.3259 21.939L32.4687 28.5206L26.3259 35.1022"
                stroke="black"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default IcoArrowRight;
