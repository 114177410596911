import Link from 'next/link';
import React from 'react';
import button from '../button/button.module.css';
import IcoArrowRightSmall from '../icons/arrow-right-small';
import lendingStyle from '../lending/lending.module.css';
import style from '../styles/styles.module.css';

export interface CustomButtonProps {
    title: string;
    href?: string;
}

const CustomButton: React.FC<CustomButtonProps> = ({ title, href = '/' }) => {
    return (
        <Link href={href}>
            <a
                className={`${button.button} ${button.sizeNormal} ${style.fontColorRose} ${lendingStyle.btn}`}
                target="_blank"
                rel="noreferrer"
            >
                <span className={style.gridRow}>
                    <span className={lendingStyle.btnInner}>{title}</span>
                    <IcoArrowRightSmall />
                </span>
            </a>
        </Link>
    );
};

export default CustomButton;
