import React from 'react';

const IcoScrollDown: React.FC = () => {
    return (
        <svg
            width="34"
            height="12"
            viewBox="0 0 34 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                opacity="0.55"
                d="M1 1.5L17 10.5L33 1.5"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default IcoScrollDown;
