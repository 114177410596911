import React from 'react';
import IcoLogoWhite from '../icons/logo-white';
import style from '../styles/styles.module.css';
import loaderStyle from './loader.module.css';

interface LoaderProps {
    height?: string | number;
}

const Loader: React.FC<LoaderProps> = ({ height }) => {
    return (
        <div
            className={`${style.bgColorBlack} ${loaderStyle.wrapper} `}
            style={{ height: height || '100vh' }}
        >
            <div className={`${loaderStyle.container}`}>
                <IcoLogoWhite />
            </div>
        </div>
    );
};

export default Loader;
