export default function loadImages(images, index, callback) {
    if (index < images.length) {
        const img = new Image();
        img.src = images[index];
        images[index] = img;
        images[index].onload = function () {
            loadImages(images, ++index, callback);
        };
    } else {
        callback(images);
    }
}
