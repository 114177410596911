import React from 'react';

const IcoArrowLeft: React.FC = () => {
    return (
        <svg
            width="56"
            height="56"
            viewBox="0 0 56 56"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx="28" cy="28" r="27.5" stroke="black" />
            <path
                d="M30 35L24 28.5L30 22"
                stroke="black"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default IcoArrowLeft;
