import React from 'react';
import Layout from '../components/layout';
import LendingPageView from '../components/lending';

const MainPage: React.FC = () => {
    return (
        <Layout headerIsVisible={true}>
            <LendingPageView />
        </Layout>
    );
};

export default MainPage;
