import React, { useCallback, useState, useRef, useEffect } from 'react';
import { xSmallViewportWidth } from '../../media-queries';
import {
    addClassToElement,
    getAppElement,
    removeClassFromElement,
    getHeaderElement,
} from '../../utils/dom';
import loadImages from '../../utils/preload-images';
import Button from '../button';
import Carousel from '../carousel';
import carousel from '../carousel/carousel.module.css';
import CustomButton from '../custom-button';
import useMediaQuery from '../hooks/use-media-query';
import IcoScrollDown from '../icons/scroll-down';
import Loader from '../loader';
import KongSlider from './kong-slider';
import lendingStyle from './lending.module.css';
import style from '../styles/styles.module.css';
import headerStyle from '../ui-kit/components/rkl-header/rkl-header.module.css';
import { IMG_PATHS, SLIDES, IMG_SEQUENCE, PARTNERS_FIRST, PARTNERS_SECOND } from './data';

export interface FeatureViewProps {}

const ACTIVE_Z_INDEX = 4;
const HEADER_OFFSET_HEIGHT = 93;
const MEET_THE_KONGS_TEXT = 'Meet the kongs'.split('');
const LendingPageView: React.FC = () => {
    const isFullLayout = useMediaQuery(xSmallViewportWidth);

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [imgSequence, setImgSequence] = useState<HTMLImageElement[]>([]);
    const [activeIndex, setActiveIndex] = useState<number>(0);
    const [bgColor, setBgColor] = useState<string>('#000000');
    const [fontColor, setFontColor] = useState<string>('#ffffff');
    const [slideBgColor, setSlideBgColor] = useState<string>('#000000');

    // Intro
    const introSectionRef = useRef<HTMLDivElement>(null);
    const introTitleRef = useRef<HTMLDivElement>(null);
    const introDescriptionRef = useRef<HTMLDivElement>(null);
    const introNavRef = useRef<HTMLDivElement>(null);
    const introSliderRef = useRef<HTMLDivElement>(null);
    // Feature
    const featureSectionRef = useRef<HTMLDivElement>(null);
    const featureTitleRef = useRef<HTMLDivElement>(null);
    const featureKongsRef = useRef<HTMLDivElement>(null);
    // Clubs
    const clubsSectionRef = useRef<HTMLDivElement>(null);
    const clubSliderRef = useRef<HTMLDivElement>(null);
    const clubSecondSliderRef = useRef<HTMLDivElement>(null);
    const clubsMainRef = useRef<HTMLDivElement>(null);
    const clubsImagesRef = useRef<HTMLDivElement>(null);
    const clubsSecondImagesRef = useRef<HTMLDivElement>(null);
    // Slider
    const slideSectionRef = useRef<HTMLDivElement>(null);
    const slideFirstRef = useRef<HTMLDivElement>(null);
    const slideLastRef = useRef<HTMLDivElement>(null);
    const slideSecondRef = useRef<HTMLDivElement>(null);
    // Video
    const videoSectionRef = useRef<HTMLDivElement>(null);
    const videoFirstRef = useRef<HTMLDivElement>(null);
    const videoSecondRef = useRef<HTMLDivElement>(null);
    // Cards
    const cardsSectionRef = useRef<HTMLDivElement>(null);
    const cardsMaskRef = useRef<HTMLDivElement>(null);
    const cardsBgRef = useRef<HTMLDivElement>(null);
    const cardsContainerRef = useRef<HTMLDivElement>(null);

    const onKongSliderChange = useCallback((bgColor: string, fontColor: string) => {
        setBgColor(bgColor);
        setFontColor(fontColor);
    }, []);
    const onSliderChange = useCallback((bgColor: string) => setSlideBgColor(bgColor), []);
    const onSwapZIndex = useCallback(() => {
        const totalImages = IMG_PATHS.length - 1;
        setActiveIndex((prev) => (prev === totalImages ? 0 : prev + 1));
    }, []);
    const scrollIntoView = useCallback(() => {
        const { current: featureSectionEl } = featureSectionRef;
        featureSectionEl.scrollIntoView({ behavior: 'smooth' });
    }, []);

    useEffect(() => {
        if (!isLoading) {
            return;
        }

        const { current: introSectionEl } = introSectionRef;
        const { current: introSliderEl } = introSliderRef;
        const { current: featureSectionEl } = featureSectionRef;
        const { current: featureTitleEl } = featureTitleRef;
        const { current: featureKongsEl } = featureKongsRef;
        const { current: clubsSectionEl } = clubsSectionRef;
        const { current: clubSecondSliderEl } = clubSecondSliderRef;
        const { current: clubsMainEl } = clubsMainRef;
        const { current: clubSliderEl } = clubSliderRef;
        const { current: clubsImagesEl } = clubsImagesRef;
        const { current: clubsSecondImagesEl } = clubsSecondImagesRef;
        const { current: slideSectionEl } = slideSectionRef;
        const { current: slideFirstEl } = slideFirstRef;
        const { current: slideSecondEl } = slideSecondRef;
        const { current: slideLastEl } = slideLastRef;
        const { current: videoSectionEl } = videoSectionRef;
        const { current: videoFirstEl } = videoFirstRef;
        const { current: videoSecondEl } = videoSecondRef;
        const { current: cardsSectionEl } = cardsSectionRef;
        const { current: cardsMaskEl } = cardsMaskRef;
        const { current: cardsBgEl } = cardsBgRef;

        const headerEl = getHeaderElement();
        const imgEl = document.getElementById('img-play');
        const slideInfo = document.getElementById('carousel-slide-info-container');
        const slideImg = document.getElementById('carousel-slide-img-container');
        const meetTheKongsEl = document.getElementById('meet-the-kongs');

        if (
            !introSectionEl ||
            !featureSectionEl ||
            !clubsSectionEl ||
            !slideSectionEl ||
            !videoSectionEl ||
            !cardsSectionEl
        ) {
            return;
        }

        let frameId: number | undefined;
        let lastScrollTop = 0;
        let textIndex = 0;
        let isScrolling;
        const tmpText = [];
        const listenerOptions: AddEventListenerOptions = { passive: true };

        const availableHeight = window.innerHeight;
        const availableWidth = window.innerWidth;
        const featureSectionTop =
            featureSectionEl.offsetTop - introSectionEl.offsetHeight / 2 - HEADER_OFFSET_HEIGHT;
        const clubsSectionTop = clubsSectionEl.offsetTop;
        const slideSectionTop = slideSectionEl.offsetTop;
        const videoSectionTop = videoSectionEl.offsetTop;
        const cardsSectionTop = cardsSectionEl.offsetTop;

        featureTitleEl.style.transform = `translate3d(0, ${availableHeight / 2}px, 0)`;
        featureKongsEl.style.transform = `translate3d(0, ${availableHeight / 2}px, 0)`;
        featureTitleEl.style.transform = `translate3d(0, ${availableHeight / 2}px, 0)`;
        featureKongsEl.style.transform = `translate3d(0, ${availableHeight / 2}px, 0)`;
        clubsMainEl.style.transform = `translate3d(0, ${availableHeight / 2}px, 0)`;
        clubSliderEl.style.transform = `translate3d(0, ${availableHeight / 2}px, 0)`;
        clubSecondSliderEl.style.transform = `translate3d(0, ${availableHeight / 2}px, 0)`;
        slideInfo.style.transform = `translate3d(0, ${availableHeight}px, 0)`;
        slideImg.style.transform = `translate3d(${availableWidth}px, 0, 0)`;

        const recalculate = () => {
            cancelAnimationFrame(frameId as number);
            frameId = requestAnimationFrame(() => {
                window.clearTimeout(isScrolling);

                const scrollY = window.scrollY;

                if (scrollY >= 0 && scrollY <= featureSectionTop + availableHeight / 2) {
                    const maxSkew = Math.min(scrollY / 30, 10);

                    introTitleRef.current.style.transform = `translate3d(0, -${scrollY / 2}px, 0)`;
                    introDescriptionRef.current.style.transform = `translate3d(0, -${
                        scrollY / 3
                    }px, 0)`;
                    introNavRef.current.style.transform = `translate3d(0, -${scrollY / 3.5}px, 0)`;

                    introSliderEl.style.transform = `translate3d(0, -${
                        scrollY / 4
                    }px, 0) skew(${maxSkew}deg)`;

                    // if scrolling was stopped return skew to normal
                    isScrolling = setTimeout(() => {
                        introSliderEl.style.transform = `translate3d(0, -${
                            scrollY / 4
                        }px, 0) skew(0deg)`;
                    }, 50);
                }

                if (scrollY >= featureSectionTop - availableHeight / 2) {
                    const deltaY = scrollY - (featureSectionTop - availableHeight / 2) + 1;
                    const newY = Math.max(availableHeight / 2 - deltaY, 0);

                    if (scrollY >= featureSectionTop + availableHeight / 2) {
                        if (scrollY > lastScrollTop) {
                            if (textIndex !== MEET_THE_KONGS_TEXT.length) {
                                tmpText.push(MEET_THE_KONGS_TEXT[textIndex]);
                                meetTheKongsEl.innerText = tmpText.join('');
                                textIndex++;
                            }
                        } else {
                            if (textIndex !== 0) {
                                tmpText.pop();
                                meetTheKongsEl.innerText = tmpText.join('');
                                textIndex--;
                            }
                        }
                    }

                    featureTitleEl.style.transform = `translate3d(0, ${newY}px, 0)`;
                    featureKongsEl.style.transform = `translate3d(0, ${
                        availableHeight / 2 - deltaY
                    }px, 0)`;
                }

                if (scrollY >= clubsSectionTop - availableHeight / 2) {
                    const deltaY = scrollY - (clubsSectionTop - availableHeight / 2);
                    const titleY = Math.max(availableHeight / 2 - deltaY * 1.5, 0);
                    const sliderY = Math.max(availableHeight / 2 - deltaY * 1.25, 0);

                    clubsMainEl.style.transform = `translate3d(0, ${titleY}px, 0)`;
                    clubSliderEl.style.transform = `translate3d(0, ${sliderY}px, 0)`;
                    clubSecondSliderEl.style.transform = `translate3d(0, ${sliderY}px, 0)`;

                    if (scrollY >= clubsSectionTop - HEADER_OFFSET_HEIGHT) {
                        const imageX = scrollY - (clubsSectionTop - HEADER_OFFSET_HEIGHT) + 1;

                        clubsImagesEl.style.transform = `translate3d(-${imageX}px, 0, 0)`;
                        clubsSecondImagesEl.style.transform = `translate3d(-${
                            imageX / 0.5
                        }px, 0, 0)`;
                    }
                }

                if (scrollY >= slideSectionTop - availableHeight && scrollY < slideSectionTop) {
                    const deltaY = scrollY - (slideSectionTop - availableHeight);
                    const contentDelta = Math.max(availableHeight - deltaY, 0);

                    slideFirstEl.style.transform = `translate3d(0, -${deltaY * 1.3}px, 0)`;
                    slideSecondEl.style.transform = `translate3d(0, -${deltaY * 1.2}px, 0)`;
                    slideLastEl.style.transform = `translate3d(0, -${deltaY * 1.1}px, 0)`;

                    slideImg.style.transform = `translate3d(${contentDelta}px, 0, 0)`;
                    slideInfo.style.transform = `translate3d(0, ${contentDelta}px, 0)`;
                }

                if (scrollY >= videoSectionTop - availableHeight) {
                    const deltaY = scrollY - (videoSectionTop - availableHeight);

                    videoFirstEl.style.transform = `translate3d(0, -${deltaY * 1.3}px, 0)`;
                    videoSecondEl.style.transform = `translate3d(0, -${deltaY * 1.2}px, 0)`;

                    if (scrollY >= videoSectionTop - HEADER_OFFSET_HEIGHT) {
                        const scrollOffset = scrollY - (videoSectionTop - HEADER_OFFSET_HEIGHT);
                        const scrollDeltaY =
                            scrollOffset / (videoSectionEl.offsetHeight / 1.5 - availableHeight);
                        const frameIndex = Math.min(
                            imgSequence.length - 1,
                            Math.ceil(scrollDeltaY * imgSequence.length),
                        );

                        if (scrollY > lastScrollTop) {
                            if (frameIndex !== imgSequence.length - 1) {
                                if (imgSequence[frameIndex]) {
                                    imgEl.setAttribute('src', imgSequence[frameIndex].src);
                                }
                            } else {
                                addClassToElement(videoSectionEl, lendingStyle.videoAnimate);
                            }
                        } else {
                            if (frameIndex < imgSequence.length - 1) {
                                removeClassFromElement(videoSectionEl, lendingStyle.videoAnimate);
                            }
                            if (frameIndex !== 0) {
                                if (imgSequence[frameIndex]) {
                                    imgEl.setAttribute('src', imgSequence[frameIndex].src);
                                }
                            }
                        }
                    }
                }

                if (scrollY >= cardsSectionTop - availableHeight / 2) {
                    const deltaY = scrollY - (cardsSectionTop - availableHeight / 2);

                    cardsBgEl.style.backgroundPositionY = `-${deltaY * 1.1}px`;

                    if (scrollY >= cardsSectionTop - HEADER_OFFSET_HEIGHT) {
                        const offset = scrollY - cardsSectionTop;
                        const newOpacity = Math.min(offset / 100, 1);

                        if (scrollY > lastScrollTop) {
                            addClassToElement(headerEl, headerStyle.headerHidden);
                            addClassToElement(cardsSectionEl, lendingStyle.kongsWithRadius);
                        } else {
                            removeClassFromElement(headerEl, headerStyle.headerHidden);
                            removeClassFromElement(cardsSectionEl, lendingStyle.kongsWithRadius);
                        }

                        cardsMaskEl.style.opacity = `${1 - newOpacity}`;
                    }
                }
                lastScrollTop = scrollY <= 0 ? 0 : scrollY;
            });
        };

        const observer =
            typeof ResizeObserver === 'undefined' ? undefined : new ResizeObserver(recalculate);
        observer?.observe(getAppElement());

        window.addEventListener('scroll', recalculate, listenerOptions);

        return () => {
            cancelAnimationFrame(frameId as number);
            observer?.disconnect();
            window.removeEventListener('scroll', recalculate, listenerOptions);
        };
    }, [isFullLayout, imgSequence, isLoading]);

    useEffect(() => {
        if (isLoading && introSectionRef) {
            addClassToElement(introSectionRef.current, lendingStyle.animateIntro);
        }
    }, [introSectionRef, isLoading]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        const imgSequencePaths = IMG_SEQUENCE.slice(0);
        loadImages(imgSequencePaths, 0, (result) => {
            setImgSequence(result);
            setIsLoading(true);
        });
    }, []);

    if (!isLoading) {
        return <Loader />;
    }

    return (
        <>
            <div ref={introSectionRef} className={lendingStyle.section}>
                <div
                    className={`${lendingStyle.full} ${lendingStyle.bgAnimate} ${style.bgColorBlack}`}
                    style={{ backgroundColor: bgColor }}
                >
                    <div className={lendingStyle.intro}>
                        <div className={lendingStyle.introContainer}>
                            <div
                                ref={introTitleRef}
                                className={`${lendingStyle.introTitle} ${style.fontSizeXLarge} ${style.fontColorWhite} ${lendingStyle.gutterM}`}
                                style={{ color: fontColor }}
                            >
                                The Metaverse Sports League
                            </div>
                            <div
                                ref={introDescriptionRef}
                                className={`${style.fontSizeXMedium} ${lendingStyle.gutterL} ${lendingStyle.introDescription}`}
                                style={{ color: fontColor }}
                            >
                                Discover a never-before-seen basketball experience, combining
                                play-to-earn with true digital ownership. Enter the Rumble Kong
                                League.
                            </div>
                            <div ref={introNavRef}>
                                <div
                                    className={`${lendingStyle.introControls} ${lendingStyle.gutterXL}`}
                                >
                                    <Button
                                        size="normal"
                                        color="rose"
                                        target="_blank"
                                        href="https://opensea.io/collection/rumble-kong-league"
                                    >
                                        Browse Collection
                                    </Button>
                                </div>
                                <div className={lendingStyle.introControls}>
                                    <div
                                        onClick={scrollIntoView}
                                        className={lendingStyle.scrollDownIcon}
                                    >
                                        <IcoScrollDown />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div ref={introSliderRef} className={lendingStyle.introKongsWrapper}>
                            <KongSlider onChange={onKongSliderChange} />
                        </div>
                    </div>
                </div>
            </div>
            <div
                ref={featureSectionRef}
                className={`${lendingStyle.section} ${lendingStyle.featureView}`}
            >
                <div className={`${lendingStyle.featureViewWrapper} ${style.bgColorBlack}`}>
                    <div
                        ref={featureTitleRef}
                        className={`${style.fontSizeXLarge} ${style.fontColorWhite} ${lendingStyle.descriptionStaticContainer}`}
                    >
                        Rumble Kongs are a set of 10,000 programmatically generated and unique NFTs
                        that will be playable avatars in the Rumble Kong League video game.&nbsp;
                        <a
                            id="meet-the-kongs"
                            href="https://opensea.io/collection/rumble-kong-league"
                            target="_blank"
                            rel="noreferrer"
                            className={`${style.fontColorRose}`}
                        >
                            &nbsp;
                        </a>
                    </div>
                    <div
                        ref={featureKongsRef}
                        className={lendingStyle.featureViewImages}
                        onClick={onSwapZIndex}
                    >
                        {IMG_PATHS.map((path, index) => (
                            <img
                                key={index}
                                width={224}
                                height={380}
                                src={path}
                                style={{
                                    zIndex: index === activeIndex ? ACTIVE_Z_INDEX : index + 1,
                                }}
                                alt=""
                            />
                        ))}
                    </div>
                </div>
            </div>
            <div
                ref={slideSectionRef}
                className={`${lendingStyle.section} ${lendingStyle.featureView}`}
            >
                <div
                    className={`${lendingStyle.slidesViewWrapper} ${lendingStyle.overflow} ${lendingStyle.bgAnimate} ${style.bgColorBlack}`}
                    style={{ backgroundColor: slideBgColor }}
                >
                    <div className={`${lendingStyle.full}`}>
                        <Carousel slides={SLIDES} onChange={onSliderChange} />
                    </div>
                </div>
                <div ref={slideFirstRef} className={carousel.sliderFadeBarFirst} />
                <div ref={slideSecondRef} className={carousel.sliderFadeBarSecond} />
                <div ref={slideLastRef} className={carousel.sliderFadeBarLast} />
            </div>
            <div
                ref={clubsSectionRef}
                className={`${lendingStyle.section} ${lendingStyle.clubsView}`}
            >
                <div className={`${lendingStyle.clubsViewWrapper} ${style.bgColorBlack}`}>
                    <div className={lendingStyle.inner}>
                        <div
                            ref={clubsMainRef}
                            className={`${style.fontSizeXLarge} ${style.fontColorWhite} ${lendingStyle.gutterM}`}
                        >
                            Backed by
                        </div>
                        <div ref={clubSliderRef} className={lendingStyle.listView}>
                            <div ref={clubsImagesRef} className={lendingStyle.listViewInner}>
                                {PARTNERS_FIRST.map((clubItem, index) => (
                                    <div
                                        className={`${style.gridRow} ${lendingStyle.listViewRow} ${style.fontSizeMedium} ${style.fontColorWhite}`}
                                        style={{
                                            backgroundColor: clubItem.bgColor,
                                        }}
                                        key={index}
                                    >
                                        <a
                                            className={`${lendingStyle.listViewImg}`}
                                            href={clubItem.href}
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            <img
                                                src={clubItem.imgPath}
                                                height={clubItem.height || 90}
                                                alt={clubItem.name}
                                            />
                                        </a>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div ref={clubSecondSliderRef} className={lendingStyle.listView}>
                            <div ref={clubsSecondImagesRef} className={lendingStyle.listViewInner}>
                                {PARTNERS_SECOND.map((clubItem, index) => (
                                    <div
                                        className={`${style.gridRow} ${lendingStyle.listViewRow} ${style.fontSizeMedium} ${style.fontColorWhite}`}
                                        style={{
                                            backgroundColor: clubItem.bgColor,
                                        }}
                                        key={index}
                                    >
                                        <a
                                            className={`${lendingStyle.listViewImg}`}
                                            href={clubItem.href}
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            <img
                                                src={clubItem.imgPath}
                                                height={clubItem.height || 90}
                                                alt={clubItem.name}
                                            />
                                        </a>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div
                ref={videoSectionRef}
                className={`${lendingStyle.section} ${lendingStyle.videoView}`}
            ></div>
            <div
                ref={videoSectionRef}
                className={`${lendingStyle.section} ${lendingStyle.videoView}`}
            >
                <div className={`${lendingStyle.videoViewWrapper} ${style.bgColorBlack}`}>
                    <div
                        className={`${lendingStyle.videoMainContent} ${lendingStyle.full} ${style.bgColorBlack} ${lendingStyle.overflow}`}
                    >
                        <div className={lendingStyle.videoContentWrapper}>
                            <div className={lendingStyle.introContainer}>
                                <div
                                    className={`${style.fontSizeXLarge} ${style.fontColorWhite} ${lendingStyle.gutterM}`}
                                >
                                    Enter The League
                                </div>
                                <div
                                    className={`${lendingStyle.videoDescription} ${style.fontSizeXMedium} ${style.fontColorLightGray} ${lendingStyle.gutterL}`}
                                >
                                    Enter the league and discover the sports Metaverse. See the
                                    OpenSea collection.
                                </div>
                                <div>
                                    <CustomButton
                                        title="OpenSea"
                                        href="https://opensea.io/collection/rumble-kong-league"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className={lendingStyle.videoFade} />
                        <div className={lendingStyle.videoWrapper}>
                            <img
                                className={lendingStyle.VideoImg}
                                id="img-play"
                                src={imgSequence[0].src}
                                alt=""
                            />
                        </div>
                    </div>
                    <div ref={videoFirstRef} className={lendingStyle.videoFadeBarFirst} />
                    <div ref={videoSecondRef} className={lendingStyle.videoFadeBarSecond} />
                </div>
            </div>
            <div ref={cardsSectionRef} className={`${lendingStyle.section}`}>
                <div className={`${lendingStyle.kongsViewWrapper} ${style.bgColorWhite}`}>
                    <div
                        ref={cardsContainerRef}
                        className={`${lendingStyle.kongsContainer} ${lendingStyle.cardsView}`}
                    >
                        <div>
                            <div
                                className={`${style.fontSizeXLarge} ${style.fontColorWhite} ${lendingStyle.gutterM}`}
                            >
                                Stay informed
                            </div>
                            <div
                                className={`${style.fontSizeXMedium} ${style.fontColorLightGray} ${lendingStyle.gutterL}`}
                            >
                                Join the community and keep up-to-date with our development process.
                            </div>
                            <Button
                                size="normal"
                                color="rose"
                                target="_blank"
                                href="https://linktr.ee/rumblekongs"
                            >
                                Lets Go
                            </Button>
                        </div>
                    </div>
                    <div ref={cardsMaskRef} className={lendingStyle.kongsMask} />
                    <div className={lendingStyle.kongsFade} />
                    <div ref={cardsBgRef} className={lendingStyle.kongsBg} />
                </div>
            </div>
        </>
    );
};

export default LendingPageView;
