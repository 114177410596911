import React from 'react';

const IcoArrowRightSmall: React.FC = () => {
    return (
        <svg
            width="8"
            height="13"
            viewBox="0 0 8 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.00001 6.50001C8.00001 6.78031 7.88236 7.04775 7.67573 7.23716L1.67573 12.7372C1.26861 13.1104 0.636044 13.0828 0.262852 12.6757C-0.11034 12.2686 -0.0828382 11.636 0.324281 11.2629L5.52011 6.50001L0.324282 1.73716C-0.0828377 1.36397 -0.11034 0.7314 0.262853 0.324281C0.636045 -0.0828378 1.26861 -0.110341 1.67573 0.262852L7.67573 5.76285C7.88236 5.95226 8.00001 6.2197 8.00001 6.50001Z"
                fill="#B87AF7"
            />
        </svg>
    );
};

export default IcoArrowRightSmall;
