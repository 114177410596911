import React, { useState, useRef, useEffect, useCallback } from 'react';
import { xSmallViewportWidth } from '../../media-queries';
import useMediaQuery from '../hooks/use-media-query';
import IcoArrowLeft from '../icons/arrow-left';
import IcoArrowRight from '../icons/arrow-right';
import carousel from './carousel.module.css';
import style from '../styles/styles.module.css';

export interface Slide {
    title: string;
    description: string;
    text?: string;
    path: string;
    bgColor: string;
}

interface Props {
    slides: Slide[];
    onChange: (bgColor: string) => void;
}

const addAnimationClassToSlides = (el: HTMLElement | null) => {
    if (el) {
        requestAnimationFrame(() => {
            requestAnimationFrame(() => {
                el.classList.add(carousel.slideAnim);
            });
        });
    }
};
const Carousel: React.FunctionComponent<Props> = ({ slides, onChange }) => {
    const isFullLayout = useMediaQuery(xSmallViewportWidth);
    const touchStartDataRef =
        useRef<{
            clientX: number;
            clientY: number;
        } | null>(null);
    const [slideIndex, setSlideIndex] = useState<number>(0);
    const currentSlide: Slide = slides[slideIndex];
    const totalSlides = slides.length;
    const slideProgress = (100 / totalSlides) * (slideIndex + 1);
    const lastSlideIndex = totalSlides - 1;
    const onSetSlide = useCallback(
        (slideIndex: number) => {
            setSlideIndex(slideIndex);
            onChange(slides[slideIndex].bgColor);
        },
        [onChange, slides],
    );
    const onSlideChange = useCallback(
        (index: number) => {
            let current = slideIndex;
            let next = (current += index);

            if (next === -1) {
                next = 2;
            }

            if (next === totalSlides) {
                next = 0;
            }
            onSetSlide(next);
        },
        [slideIndex, onSetSlide, totalSlides],
    );
    const onTouchStart = useCallback((event: React.TouchEvent<HTMLDivElement>) => {
        const { touches } = event as React.TouchEvent;
        const touchData: React.Touch = touches && touches[0];

        if (touchData) {
            touchStartDataRef.current = {
                clientX: touchData.clientX,
                clientY: touchData.clientY,
            };
        }
    }, []);
    const onTouchEnd = useCallback(
        (event: React.TouchEvent<HTMLDivElement>) => {
            const touches: React.TouchList = event.changedTouches;
            const touchEndData: React.Touch = touches && touches[0];
            const { current: touchStartData } = touchStartDataRef;

            if (!touchEndData || !touchStartData) {
                return;
            }

            touchStartDataRef.current = null;

            const touchEndX: number = touchEndData.clientX;
            const touchEndY: number = touchEndData.clientY;
            const xDiff: number = touchStartData.clientX - touchEndX;
            const yDiff: number = touchStartData.clientY - touchEndY;
            const absXDiff: number = Math.abs(xDiff);
            const isHorizontalSwipe: boolean = absXDiff > Math.abs(yDiff);

            if (isHorizontalSwipe && absXDiff > 40) {
                const nextSlideIndex: number = slideIndex + (xDiff > 0 ? 1 : -1);
                let next = 0;
                if (nextSlideIndex >= 0 && nextSlideIndex <= lastSlideIndex) {
                    next = nextSlideIndex;
                }
                onSetSlide(next);
            }
        },
        [lastSlideIndex, slideIndex, onSetSlide],
    );

    useEffect(() => {
        onChange(slides[slideIndex].bgColor);
        setSlideIndex(0);
    }, [slides, onChange, slideIndex]);

    const imgWidth = isFullLayout ? 255 : 120;
    const startX = (imgWidth * totalSlides) / 2;
    let offsetX = 0;

    if (slideIndex === 0) {
        offsetX = 0;
    } else if (slideIndex === lastSlideIndex) {
        offsetX = startX;
    } else if (slideIndex !== 0) {
        offsetX = (imgWidth * slideIndex) / 2;
    }

    return (
        <div className={carousel.layout} onTouchStart={onTouchStart} onTouchEnd={onTouchEnd}>
            <div className={carousel.slide} key={`slide${slideIndex}`}>
                <div id="carousel-slide-info-container" className={carousel.slideContainer}>
                    <div className={`${style.fontSizeXMedium} ${carousel.slideTitle}`}>
                        {currentSlide.title}
                    </div>
                    <div className={`${style.fontSizeXLarge} ${carousel.slideDescription}`}>
                        {currentSlide.description}
                    </div>
                    {currentSlide.title && (
                        <div className={carousel.slideText}>{currentSlide.text}</div>
                    )}
                    {isFullLayout && (
                        <div className={carousel.sliderNavigation}>
                            <div
                                onClick={onSlideChange.bind(null, -1)}
                                className={carousel.sliderNavigationBtn}
                            >
                                <IcoArrowLeft />
                            </div>
                            <div
                                onClick={onSlideChange.bind(null, 1)}
                                className={carousel.sliderNavigationBtn}
                            >
                                <IcoArrowRight />
                            </div>
                            <div className={style.fontSizeXSmall}>
                                {slideIndex + 1}&nbsp;of&nbsp;{totalSlides}
                            </div>
                        </div>
                    )}
                </div>
                <div id="carousel-slide-img-container" className={carousel.slideImgContainer}>
                    <div
                        className={`${carousel.slideImages}`}
                        style={{
                            transform: `translate(${startX - offsetX}px, 0)`,
                        }}
                        ref={addAnimationClassToSlides}
                    >
                        {slides.map((slide: Slide, index: number) => (
                            <img
                                key={index}
                                width={isFullLayout ? 255 : 224}
                                height={isFullLayout ? 452 : 380}
                                className={`${
                                    index === slideIndex ? carousel.activeSlide : carousel.slideImg
                                }`}
                                src={slide.path}
                                alt=""
                                onClick={onSetSlide.bind(null, index)}
                            />
                        ))}
                    </div>
                </div>
                {!isFullLayout && (
                    <div className={carousel.slideProgressContainer}>
                        <div
                            className={carousel.slideProgressFill}
                            style={{ width: `${slideProgress}%` }}
                        />
                        <div className={carousel.slideProgress} />
                    </div>
                )}
            </div>
        </div>
    );
};

export default React.memo(Carousel);
