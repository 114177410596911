import { Slide } from '../carousel';

export const PARTNERS_FIRST = [
    {
        name: 'JDS',
        href: 'https://www.jdssports.co/crypto/',
        height: 180,
        bgColor: '#fb8d00',
        imgPath: '/images/logos/jds.png',
    },
    {
        name: 'IDEO CoLab',
        href: 'https://www.ideocolab.com/',
        bgColor: '#000',
        height: 180,
        imgPath: '/images/logos/ideo.png',
    },
    {
        name: 'CAA Sports',
        href: 'https://www.caa.com/',
        bgColor: '#d20b01',
        height: 180,
        imgPath: '/images/logos/caa-sports.jpg',
    },
    {
        name: 'Framework',
        href: 'https://framework.ventures/',
        height: 180,
        bgColor: '#fff',
        imgPath: '/images/logos/framework.png',
    },
    {
        name: 'Paul George',
        href: 'https://twitter.com/Yg_Trece/',
        height: 180,
        bgColor: '#000',
        imgPath: '/images/logos/pg13.png',
    },
    {
        name: 'Animoca Brands',
        href: 'https://www.animocabrands.com/',
        bgColor: '#000',
        imgPath: '/images/logos/animoca.png',
    },
    {
        name: 'Skyvision Capital',
        href: 'https://www.skyvisioncapital.com/',
        bgColor: '#000',
        height: 180,
        imgPath: '/images/logos/svc.jpg',
    },
    {
        name: 'Victory Creative Group',
        href: 'https://www.wearevictory.com/',
        bgColor: '#000',
        height: 180,
        imgPath: '/images/logos/victory.png',
    },
];
export const PARTNERS_SECOND = [
    {
        name: 'Victory Creative Group',
        href: 'https://www.wearevictory.com/',
        bgColor: '#000',
        height: 180,
        imgPath: '/images/logos/victory.png',
    },
    {
        name: 'Skyvision Capital',
        href: 'https://www.skyvisioncapital.com/',
        bgColor: '#000',
        height: 180,
        imgPath: '/images/logos/svc.jpg',
    },
    {
        name: 'Animoca Brands',
        href: 'https://www.animocabrands.com/',
        bgColor: '#000',
        imgPath: '/images/logos/animoca.png',
    },
    {
        name: 'Paul George',
        href: 'https://twitter.com/Yg_Trece/',
        height: 180,
        bgColor: '#000',
        imgPath: '/images/logos/pg13.png',
    },
    {
        name: 'Framework',
        href: 'https://framework.ventures/',
        height: 180,
        bgColor: '#fff',
        imgPath: '/images/logos/framework.png',
    },
    {
        name: 'CAA Sports',
        href: 'https://www.caa.com/',
        bgColor: '#d20b01',
        height: 180,
        imgPath: '/images/logos/caa-sports.jpg',
    },
    {
        name: 'IDEO CoLab',
        href: 'https://www.ideocolab.com/',
        bgColor: '#000',
        height: 180,
        imgPath: '/images/logos/ideo.png',
    },
    {
        name: 'JDS',
        href: 'https://www.jdssports.co/crypto/',
        height: 180,
        bgColor: '#fb8d00',
        imgPath: '/images/logos/jds.png',
    },
];
export const IMG_PATHS = [
    '/images/kongs/kong1.png',
    '/images/kongs/kong2.png',
    '/images/kongs/kong3.png',
];
export const SLIDES: Slide[] = [
    {
        title: ' ',
        description: "The Metaverse's\nbasketball league",
        text: 'Rumble Kong League is built to bring thrilling basketball matches to web and mobile, giving anyone a chance to compete for the title.',
        path: '/images/kongs-card/slide-001.png',
        bgColor: '#B87AF7',
    },
    {
        title: ' ',
        description: 'Play-To-Earn',
        text: 'Play matches, earn rewards. Built with play-to-earn functionalities in mind, Rumble Kong League is more than a game. Built for the future, we are giving 100% commercial rights to Kong and Club holders.',
        path: '/images/kongs-card/slide-002.png',
        bgColor: '#FEDA5E',
    },
    {
        title: ' ',
        description: 'Digital Fashion',
        text: 'Rumble Kong League is providing users, brands and celebrities entirely new ways to engage with their fans and reach a new audience. Think customizable wearables, banners, stadiums, players, etc, completely customizable by its owners.',
        path: '/images/kongs-card/slide-003.png',
        bgColor: '#9BFCDF',
    },
];
export const IMG_SEQUENCE = [
    '/images/sequence/1_0000.jpeg',
    '/images/sequence/1_0002.jpeg',
    '/images/sequence/1_0003.jpeg',
    '/images/sequence/1_0004.jpeg',
    '/images/sequence/1_0005.jpeg',
    '/images/sequence/1_0006.jpeg',
    '/images/sequence/1_0007.jpeg',
    '/images/sequence/1_0008.jpeg',
    '/images/sequence/1_0009.jpeg',
    '/images/sequence/1_0010.jpeg',
    '/images/sequence/1_0011.jpeg',
    '/images/sequence/1_0012.jpeg',
    '/images/sequence/1_0013.jpeg',
    '/images/sequence/1_0014.jpeg',
    '/images/sequence/1_0015.jpeg',
    '/images/sequence/1_0016.jpeg',
    '/images/sequence/1_0017.jpeg',
    '/images/sequence/1_0018.jpeg',
    '/images/sequence/1_0019.jpeg',
    '/images/sequence/1_0020.jpeg',
    '/images/sequence/1_0021.jpeg',
    '/images/sequence/1_0022.jpeg',
    '/images/sequence/1_0023.jpeg',
    '/images/sequence/1_0024.jpeg',
    '/images/sequence/1_0025.jpeg',
    '/images/sequence/1_0026.jpeg',
    '/images/sequence/1_0027.jpeg',
    '/images/sequence/1_0028.jpeg',
    '/images/sequence/1_0029.jpeg',
    '/images/sequence/1_0030.jpeg',
    '/images/sequence/1_0031.jpeg',
    '/images/sequence/1_0032.jpeg',
    '/images/sequence/1_0033.jpeg',
    '/images/sequence/1_0034.jpeg',
    '/images/sequence/1_0035.jpeg',
    '/images/sequence/1_0036.jpeg',
    '/images/sequence/1_0037.jpeg',
    '/images/sequence/1_0038.jpeg',
    '/images/sequence/1_0039.jpeg',
    '/images/sequence/1_0040.jpeg',
    '/images/sequence/1_0041.jpeg',
    '/images/sequence/1_0042.jpeg',
    '/images/sequence/1_0043.jpeg',
    '/images/sequence/1_0044.jpeg',
    '/images/sequence/1_0045.jpeg',
    '/images/sequence/1_0046.jpeg',
    '/images/sequence/1_0047.jpeg',
    '/images/sequence/1_0048.jpeg',
    '/images/sequence/1_0049.jpeg',
    '/images/sequence/1_0050.jpeg',
];
