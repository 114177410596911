import React, { useCallback } from 'react';
import lendingStyle from './lending.module.css';

export interface KongSliderProps {
    onChange: (bgColor: string, fontColor: string) => void;
}

const kongs = [
    {
        path: '/images/kongs-preview/kong-001.png',
        w: 98,
        h: 98,
        mw: 76,
        mh: 76,
        bgColor: '#C7002A',
        fontColor: '#ffffff',
        cardPath: '/images/kongs/kong1.png',
    },
    {
        path: '/images/kongs-preview/kong-003.png',
        w: 98,
        h: 98,
        mw: 76,
        mh: 76,
        bgColor: '#7A59E2',
        fontColor: '#ffffff',
        cardPath: '/images/kongs-card/slide-002.png',
    },
    {
        path: '/images/kongs-preview/kong-004.png',
        w: 98,
        h: 98,
        mw: 76,
        mh: 76,
        bgColor: '#686E83',
        fontColor: '#ffffff',
        cardPath: '/images/kongs/kong2.png',
    },
    {
        path: '/images/kongs-preview/kong-002.png',
        w: 98,
        h: 98,
        mw: 76,
        mh: 76,
        bgColor: '#004BF1',
        fontColor: '#ffffff',
        cardPath: '/images/kongs-card/slide-001.png',
    },
    {
        path: '/images/kongs-preview/kong-005.png',
        w: 98,
        h: 98,
        mw: 76,
        mh: 76,
        bgColor: '#C7002A',
        fontColor: '#ffffff',
        cardPath: '/images/kongs/kong3.png',
    },
    {
        path: '/images/kongs-preview/kong-006.png',
        w: 98,
        h: 98,
        mw: 76,
        mh: 76,
        bgColor: '#686E83',
        fontColor: '#ffffff',
        cardPath: '/images/kongs-card/slide-003.png',
    },
    {
        path: '/images/kongs-preview/kong-007.png',
        w: 98,
        h: 98,
        mw: 76,
        mh: 76,
        bgColor: '#FFDA3D',
        fontColor: '#000000',
        cardPath: '/images/kongs-card/slide-003.png',
    },
    {
        path: '/images/kongs-preview/kong-008.png',
        w: 98,
        h: 98,
        mw: 76,
        mh: 76,
        bgColor: '#7A59E2',
        fontColor: '#ffffff',
        cardPath: '/images/kongs-card/slide-003.png',
    },
    {
        path: '/images/kongs-preview/kong-012.png',
        w: 98,
        h: 98,
        mw: 76,
        mh: 76,
        bgColor: '#FFDA3D',
        fontColor: '#000000',
        cardPath: '/images/kongs-card/slide-003.png',
    },
    {
        path: '/images/kongs-preview/kong-010.png',
        w: 98,
        h: 98,
        mw: 76,
        mh: 76,
        bgColor: '#C7002A',
        fontColor: '#',
        cardPath: '/images/kongs-card/slide-003.png',
    },
    {
        path: '/images/kongs-preview/kong-011.png',
        w: 98,
        h: 98,
        mw: 76,
        mh: 76,
        bgColor: '#B9C1DF',
        fontColor: '#000000',
        cardPath: '/images/kongs-card/slide-003.png',
    },
    {
        path: '/images/kongs-preview/kong-013.png',
        w: 98,
        h: 98,
        mw: 76,
        mh: 76,
        bgColor: '#F4A600',
        fontColor: '#000000',
        cardPath: '/images/kongs-card/slide-003.png',
    },
    {
        path: '/images/kongs-preview/kong-009.png',
        w: 98,
        h: 98,
        mw: 76,
        mh: 76,
        bgColor: '#7A59E2',
        fontColor: '#ffffff',
        cardPath: '/images/kongs-card/slide-003.png',
    },
    {
        path: '/images/kongs-preview/kong-014.png',
        w: 98,
        h: 98,
        mw: 76,
        mh: 76,
        bgColor: '#FFDA3D',
        fontColor: '#000000',
        cardPath: '/images/kongs-card/slide-003.png',
    },
    {
        path: '/images/kongs-preview/kong-017.png',
        w: 98,
        h: 98,
        mw: 76,
        mh: 76,
        bgColor: '#FE62C2',
        fontColor: '#000000',
        cardPath: '/images/kongs-card/slide-003.png',
    },
    {
        path: '/images/kongs-preview/kong-015.png',
        w: 98,
        h: 98,
        mw: 76,
        mh: 76,
        bgColor: '#A45E4C',
        fontColor: '#000000',
        cardPath: '/images/kongs-card/slide-003.png',
    },
    {
        path: '/images/kongs-preview/kong-016.png',
        w: 98,
        h: 98,
        mw: 76,
        mh: 76,
        bgColor: '#C7002A',
        fontColor: '#000000',
        cardPath: '/images/kongs-card/slide-003.png',
    },
    {
        path: '/images/kongs-preview/kong-018.png',
        w: 98,
        h: 98,
        mw: 76,
        mh: 76,
        bgColor: '#FE62C2',
        fontColor: '#000000',
        cardPath: '/images/kongs-card/slide-003.png',
    },
    {
        path: '/images/kongs-preview/kong-020.png',
        w: 98,
        h: 98,
        mw: 76,
        mh: 76,
        bgColor: '#00D8CE',
        fontColor: '#000000',
        cardPath: '/images/kongs-card/slide-003.png',
    },
    {
        path: '/images/kongs-preview/kong-021.png',
        w: 98,
        h: 98,
        mw: 76,
        mh: 76,
        bgColor: '#004BF1',
        fontColor: '#000000',
        cardPath: '/images/kongs-card/slide-003.png',
    },
    {
        path: '/images/kongs-preview/kong-019.png',
        w: 98,
        h: 98,
        mw: 76,
        mh: 76,
        bgColor: '#FFDA3D',
        fontColor: '#000000',
        cardPath: '/images/kongs-card/slide-003.png',
    },
    {
        path: '/images/kongs-preview/kong-022.png',
        w: 98,
        h: 98,
        mw: 76,
        mh: 76,
        bgColor: '#686E83',
        fontColor: '#000000',
        cardPath: '/images/kongs-card/slide-003.png',
    },
    {
        path: '/images/kongs-preview/kong-023.png',
        w: 98,
        h: 98,
        mw: 76,
        mh: 76,
        bgColor: '#004BF1',
        fontColor: '#000000',
        cardPath: '/images/kongs-card/slide-003.png',
    },
    {
        path: '/images/kongs-preview/kong-026.png',
        w: 98,
        h: 98,
        mw: 76,
        mh: 76,
        bgColor: '#686E83',
        fontColor: '#000000',
        cardPath: '/images/kongs-card/slide-003.png',
    },
    {
        path: '/images/kongs-preview/kong-024.png',
        w: 98,
        h: 98,
        mw: 76,
        mh: 76,
        bgColor: '#F4A600',
        fontColor: '#000000',
        cardPath: '/images/kongs-card/slide-003.png',
    },
    {
        path: '/images/kongs-preview/kong-025.png',
        w: 98,
        h: 98,
        mw: 76,
        mh: 76,
        bgColor: '#A45E4C',
        fontColor: '#000000',
        cardPath: '/images/kongs-card/slide-003.png',
    },
    {
        path: '/images/kongs-preview/kong-027.png',
        w: 98,
        h: 98,
        mw: 76,
        mh: 76,
        bgColor: '#686E83',
        fontColor: '#000000',
        cardPath: '/images/kongs-card/slide-003.png',
    },
    {
        path: '/images/kongs-preview/kong-029.png',
        w: 98,
        h: 98,
        mw: 76,
        mh: 76,
        bgColor: '#004BF1',
        fontColor: '#000000',
        cardPath: '/images/kongs-card/slide-003.png',
    },
    {
        path: '/images/kongs-preview/kong-030.png',
        w: 98,
        h: 98,
        mw: 76,
        mh: 76,
        bgColor: '#FFDA3D',
        fontColor: '#000000',
        cardPath: '/images/kongs-card/slide-003.png',
    },
    {
        path: '/images/kongs-preview/kong-028.png',
        w: 98,
        h: 98,
        mw: 76,
        mh: 76,
        bgColor: '#004BF1',
        fontColor: '#000000',
        cardPath: '/images/kongs-card/slide-003.png',
    },
    {
        path: '/images/kongs-preview/kong-031.png',
        w: 98,
        h: 98,
        mw: 76,
        mh: 76,
        bgColor: '#A45E4C',
        fontColor: '#000000',
        cardPath: '/images/kongs-card/slide-003.png',
    },
    {
        path: '/images/kongs-preview/kong-032.png',
        w: 98,
        h: 98,
        mw: 76,
        mh: 76,
        bgColor: '#004BF1',
        fontColor: '#000000',
        cardPath: '/images/kongs-card/slide-003.png',
    },
    {
        path: '/images/kongs-preview/kong-033.png',
        w: 98,
        h: 98,
        mw: 76,
        mh: 76,
        bgColor: '#FE62C2',
        fontColor: '#000000',
        cardPath: '/images/kongs-card/slide-003.png',
    },
    {
        path: '/images/kongs-preview/kong-034.png',
        w: 98,
        h: 98,
        mw: 76,
        mh: 76,
        bgColor: '#B9C1DF',
        fontColor: '#000000',
        cardPath: '/images/kongs-card/slide-003.png',
    },
    {
        path: '/images/kongs-preview/kong-035.png',
        w: 98,
        h: 98,
        mw: 76,
        mh: 76,
        bgColor: '#C7002A',
        fontColor: '#000000',
        cardPath: '/images/kongs-card/slide-003.png',
    },
    {
        path: '/images/kongs-preview/kong-036.png',
        w: 98,
        h: 98,
        mw: 76,
        mh: 76,
        bgColor: '#F4A600',
        fontColor: '#000000',
        cardPath: '/images/kongs-card/slide-003.png',
    },
    {
        path: '/images/kongs-preview/kong-037.png',
        w: 98,
        h: 98,
        mw: 76,
        mh: 76,
        bgColor: '#FE62C2',
        fontColor: '#000000',
        cardPath: '/images/kongs-card/slide-003.png',
    },
    {
        path: '/images/kongs-preview/kong-038.png',
        w: 98,
        h: 98,
        mw: 76,
        mh: 76,
        bgColor: '#00D8CE',
        fontColor: '#000000',
        cardPath: '/images/kongs-card/slide-003.png',
    },
];
export const addActiveClass = (el) => {
    if (el) {
        requestAnimationFrame(() => {
            requestAnimationFrame(() => {
                el.classList.add(lendingStyle.activeKong);
            });
        });
    }
};
const KongSlider: React.FC<KongSliderProps> = ({ onChange }) => {
    const onKongEnter = useCallback(
        (e: React.MouseEvent) => {
            onChange(
                e.currentTarget.getAttribute('data-color'),
                e.currentTarget.getAttribute('data-font-color'),
            );
        },
        [onChange],
    );
    const onKongLeave = useCallback(() => onChange('#000000', '#ffffff'), [onChange]);

    return (
        <div className={lendingStyle.slider}>
            <div className={lendingStyle.sliderContainer}>
                {kongs.map((kong, index) => (
                    <img
                        className={lendingStyle.sliderImg}
                        data-color={kong.bgColor}
                        data-font-color={kong.fontColor}
                        data-src={kong.cardPath}
                        key={`${index}-${kong.path}`}
                        src={kong.path}
                        width={kong.w}
                        height={kong.h}
                        onMouseEnter={onKongEnter}
                        onMouseLeave={onKongLeave}
                        alt={`#${index}`}
                    />
                ))}
            </div>
        </div>
    );
};

export default KongSlider;
